// Generated by ReScript, PLEASE EDIT WITH CARE

import ContextMenuTsx from "./ContextMenu.tsx";

var make = ContextMenuTsx;

export {
  make ,
  
}
/* make Not a pure module */
