// Generated by ReScript, PLEASE EDIT WITH CARE

import AugmentImageTsx from "./AugmentImage.tsx";

var make = AugmentImageTsx;

export {
  make ,
  
}
/* make Not a pure module */
