import { make as HomePage, extractHomePageComp } from "../src/HomePage.bs";
import { allPatches, lastPatch } from "common/src/Config.ts";

export default function Player({ comps, duoComps, currStats, pastStats }) {
  return (
    <>
      <HomePage
        compsStats={comps}
        duoCompsStats={duoComps}
        currStats={currStats}
        pastStats={pastStats}
      />
    </>
  );
}

export async function getStaticProps() {
  const compsResp = await fetch(
    `https://api.tft.tools/team-compositions/1/${lastPatch}`,
  );
  const comps = await compsResp.json();

  const groups = comps.groups
    .map((x) => x.full)
    .filter((x) => {
      return (x.count / comps.count) * 8 > 0.05;
    })
    .sort((c1, c2) => c1.place - c2.place)
    .map(extractHomePageComp);

  const currResp = await fetch(
    `https://d2.tft.tools/stats2/general/1100/${allPatches[0]}/1`,
  );
  const currStats = await currResp.json();

  let pastStats;
  try {
    const pastResp = await fetch(
      `https://d2.tft.tools/stats2/general/1100/${allPatches[1]}/1`,
    );
    pastStats = await pastResp.json();
  } catch (_) {
    pastStats = {};
  }

  return {
    props: {
      comps: [comps.count, groups.slice(0, 5)],
      pastStats,
      currStats,
    },
    revalidate: 3600,
  };
}
