// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Route from "./Route.bs.js";
import * as React from "react";
import Link from "next/link";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Link$1(Props) {
  var id = Props.id;
  var target = Props.target;
  var stopPropagationOpt = Props.stopPropagation;
  var onContextMenu = Props.onContextMenu;
  var prefetchOpt = Props.prefetch;
  var shallow = Props.shallow;
  var className = Props.className;
  var onClick = Props.onClick;
  var route = Props.route;
  var children = Props.children;
  var stopPropagation = stopPropagationOpt !== undefined ? stopPropagationOpt : false;
  var prefetch = prefetchOpt !== undefined ? prefetchOpt : false;
  var tmp = {
    className: className !== undefined ? className + " no-webkit-preview" : "no-webkit-preview",
    onClick: (function (e) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        if (e.button === 0 && onClick !== undefined) {
          e.preventDefault();
          e.stopPropagation();
          return Curry._1(onClick, undefined);
        }
        
      }),
    passHref: true,
    prefetch: prefetch,
    href: Route.toString(route),
    children: children
  };
  if (id !== undefined) {
    tmp.id = id;
  }
  if (onContextMenu !== undefined) {
    tmp.onContextMenu = Caml_option.valFromOption(onContextMenu);
  }
  if (target !== undefined) {
    tmp.target = target;
  }
  if (shallow !== undefined) {
    tmp.shallow = shallow;
  }
  return React.createElement(Link, tmp);
}

var make = Link$1;

export {
  make ,
  
}
/* Route Not a pure module */
