// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Route from "../Route.bs.js";
import * as React from "react";
import * as Region from "common/src/Region.bs.js";
import * as Router from "common/src/Router.bs.js";
import JsCookie from "js-cookie";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as Translation from "common/src/bindings/Translation.bs.js";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";

function LbPlayerSearch(Props) {
  var router = Router.use(undefined);
  var screenSize = ScreenAtom.use(undefined);
  var t = Translation.useT(/* Common */0);
  var match = React.useState(function () {
        return "";
      });
  var setSummonerName = match[1];
  var summonerName = match[0];
  var match$1 = Region.useRegionSelect(undefined);
  var setRegionSelect = match$1[1];
  var regionSelect = match$1[0];
  var onSearchPlayer = function (region, playerName) {
    var playerName$1 = playerName.trim();
    if (playerName$1 === "") {
      return ;
    }
    JsCookie.set("player-region", Region.toApiName(region));
    var match;
    if (playerName$1.includes("#")) {
      var split = playerName$1.split("#");
      match = [
        Belt_Array.getExn(split, 0),
        Belt_Array.get(split, 1)
      ];
    } else {
      match = [
        playerName$1,
        undefined
      ];
    }
    return router.push(Route.toString({
                    TAG: /* Player */2,
                    _0: {
                      region: region,
                      playerName: match[0],
                      tag: match[1],
                      match: undefined
                    }
                  }), undefined, {
                shallow: true
              });
  };
  return React.createElement("div", {
              className: "pl-search-bg sm:rounded pl-[14px] pr-[14px] pb-[6px] pt-[10px] w-screen sm:w-[480px] mb-8 border-b-2 border-primary-11"
            }, React.createElement("div", {
                  className: "flex items-center gap-3"
                }, React.createElement("div", {
                      className: ""
                    }, React.createElement(Select, {
                          native: screenSize >= 4,
                          classes: {
                            select: "pl-2 pt-[7px] text-[18px] !min-w-[38px]"
                          },
                          value: Region.toApiName(regionSelect),
                          onChange: (function (e) {
                              var v = e.target.value;
                              return Curry._1(setRegionSelect, (function (param) {
                                            return Region.fromApiName(v);
                                          }));
                            }),
                          variant: "standard",
                          disableUnderline: true,
                          children: Belt_Array.map(Region.all, (function (region) {
                                  if (screenSize >= 4) {
                                    return React.createElement("option", {
                                                key: Region.toApiName(region),
                                                value: Region.toApiName(region)
                                              }, Region.toName3(region));
                                  } else {
                                    return React.createElement(MenuItem, {
                                                value: Region.toApiName(region),
                                                children: Region.toName3(region),
                                                key: Region.toApiName(region)
                                              });
                                  }
                                }))
                        })), React.createElement("div", {
                      className: "notranslate flex-grow w-full"
                    }, React.createElement(InputBase, {
                          classes: {
                            root: "w-full text-[20px]"
                          },
                          onKeyDown: (function (e) {
                              if (e.which === 13) {
                                return onSearchPlayer(regionSelect, summonerName);
                              }
                              
                            }),
                          autoFocus: true,
                          placeholder: t("Search Player or Riot ID"),
                          value: summonerName,
                          onChange: (function (e) {
                              var v = e.target.value;
                              return Curry._1(setSummonerName, (function (param) {
                                            return v;
                                          }));
                            }),
                          variant: "standard"
                        })), React.createElement(IconButton, {
                      classes: {
                        root: "text-[28px]"
                      },
                      color: "primary",
                      onClick: (function (param) {
                          return onSearchPlayer(regionSelect, summonerName);
                        }),
                      children: React.createElement(Search, {
                            fontSize: "inherit"
                          })
                    })));
}

var make = LbPlayerSearch;

export {
  make ,
  
}
/* Route Not a pure module */
