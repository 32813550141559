import { Menu, MenuItem } from "@mui/material"

export default function ContextMenu({ anchorEl, onClose, options }: any) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl != null}
      onClose={onClose}
    >
      {options.map(([key, onClick, el]: any) => (
        <MenuItem key={key} onClick={onClick}>
          {el}
        </MenuItem>
      ))}
    </Menu>
  )
}