// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function ContentSection(Props) {
  var id = Props.id;
  var levelOpt = Props.level;
  var classNameOpt = Props.className;
  var title = Props.title;
  var children = Props.children;
  var noPaddingOpt = Props.noPadding;
  var level = levelOpt !== undefined ? levelOpt : 1;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var noPadding = noPaddingOpt !== undefined ? noPaddingOpt : false;
  var tmp = {
    className: (
      noPadding ? "" : "p-4"
    ) + " rounded text-white1 " + className + " " + (
      level !== 0 ? (
          level !== 1 ? "bg-bg2" : "bg-bg"
        ) : "bg-bg0"
    )
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, title !== undefined ? React.createElement("h4", {
                    className: "text-xl font-medium pb-5 pt-1"
                  }, title) : null, children);
}

function ContentSection$Header(Props) {
  var title = Props.title;
  var rightContent = Props.rightContent;
  return React.createElement("div", {
              className: "flex justify-between items-center rounded-t p-4 bg-bg2"
            }, React.createElement("h4", {
                  className: "text-xl font-medium"
                }, title), Belt_Option.getWithDefault(rightContent, null));
}

var Header = {
  make: ContentSection$Header
};

var make = ContentSection;

export {
  make ,
  Header ,
  
}
/* react Not a pure module */
