// Generated by ReScript, PLEASE EDIT WITH CARE

import AdLayoutTsx from "./AdLayout.tsx";

var make = AdLayoutTsx;

export {
  make ,
  
}
/* make Not a pure module */
