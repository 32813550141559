// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation from "../bindings/Translation.bs.js";

function TraitName0(Props) {
  var trait = Props.trait;
  var t = Translation.useT(/* Traits */2);
  return t(trait);
}

var make = TraitName0;

export {
  make ,
  
}
/* Translation Not a pure module */
