// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Link from "../Link.bs.js";
import * as React from "react";
import * as Global from "common/src/Global.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Aperture from "common/src/Aperture.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as UnitImage from "common/src/tft-static/UnitImage.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TraitImage from "common/src/tft-static/TraitImage.bs.js";
import * as ApertureCtx from "../ctx/ApertureCtx.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Translation from "common/src/bindings/Translation.bs.js";
import * as UnitTooltip from "common/src/tft-static/UnitTooltip.bs.js";
import * as AugmentImage from "common/src/tft-static/AugmentImage.bs.js";
import * as TraitTooltip from "common/src/tft-static/TraitTooltip.bs.js";
import * as AugmentTooltip from "common/src/tft-static/AugmentTooltip.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as ContentSection from "common/src/ContentSection.bs.js";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

function getTopTrends(units, traits) {
  var trends = [];
  var units$1 = Belt_Array.map(units, (function (param) {
          var s = param[1];
          return [
                  param[0],
                  s.place - param[2].place,
                  s.place
                ];
        }));
  var traits$1 = Belt_Array.map(traits, (function (param) {
          var ps = param[2];
          return [
                  param[0],
                  param[1].place - ps.place,
                  ps.place
                ];
        }));
  Belt_Array.forEach(Belt_Array.slice(Belt_SortArray.stableSortBy(units$1, (function (param, param$1) {
                  return Caml.caml_float_compare(param[1], param$1[1]);
                })), 0, 7), (function (param) {
          trends.push([
                {
                  TAG: /* Unit */0,
                  _0: param[0]
                },
                param[1],
                param[2]
              ]);
          
        }));
  Belt_Array.forEach(Belt_Array.slice(Belt_SortArray.stableSortBy(units$1, (function (param, param$1) {
                  return Caml.caml_float_compare(param$1[1], param[1]);
                })), 0, 6), (function (param) {
          trends.push([
                {
                  TAG: /* Unit */0,
                  _0: param[0]
                },
                param[1],
                param[2]
              ]);
          
        }));
  Belt_Array.forEach(Belt_Array.slice(Belt_SortArray.stableSortBy(traits$1, (function (param, param$1) {
                  return Caml.caml_float_compare(param[1], param$1[1]);
                })), 0, 6), (function (param) {
          trends.push([
                {
                  TAG: /* Trait */1,
                  _0: param[0]
                },
                param[1],
                param[2]
              ]);
          
        }));
  Belt_Array.forEach(Belt_Array.slice(Belt_SortArray.stableSortBy(traits$1, (function (param, param$1) {
                  return Caml.caml_float_compare(param$1[1], param[1]);
                })), 0, 4), (function (param) {
          trends.push([
                {
                  TAG: /* Trait */1,
                  _0: param[0]
                },
                param[1],
                param[2]
              ]);
          
        }));
  return Belt_SortArray.stableSortBy(trends, (function (param, param$1) {
                return Caml.caml_float_compare(Math.abs(param$1[1]), Math.abs(param[1]));
              }));
}

function TopTrends$TopTrend(Props) {
  var entity = Props.entity;
  var delta = Props.delta;
  var place = Props.place;
  var tmp;
  switch (entity.TAG | 0) {
    case /* Unit */0 :
        var unitId = entity._0;
        tmp = React.createElement(UnitTooltip.make, {
              unit: unitId,
              children: React.createElement(UnitImage.make, {
                    unitId: unitId,
                    size: 30,
                    className: "mr-[2px]"
                  })
            });
        break;
    case /* Trait */1 :
        var split = entity._0.split("__");
        var traitId = Belt_Array.getExn(split, 0);
        var rank = Belt_Option.getExn(Belt_Int.fromString(Belt_Array.getExn(split, 1)));
        tmp = React.createElement(TraitTooltip.make, {
              trait: traitId,
              rank: rank,
              children: React.createElement(TraitImage.PlainIcon2.make, {
                    traitId: traitId,
                    rank: rank,
                    size: 32
                  })
            });
        break;
    case /* Augment */2 :
        var id = entity._0;
        tmp = React.createElement(AugmentTooltip.make, {
              id: id,
              children: React.createElement(AugmentImage.make, {
                    className: "mr-[2px]",
                    augId: id,
                    size: 30
                  })
            });
        break;
    
  }
  return React.createElement("div", {
              className: "flex-shrink-0 w-[164px] mx-2 flex items-center " + (
                delta <= 0 ? "text-green-11" : "text-red-11"
              )
            }, tmp, React.createElement("div", {
                  className: "ml-[6px] text-lg text-white1"
                }, Global.toFixed(undefined, place)), React.createElement("div", {
                  className: "px-[2px]"
                }, delta > 0 ? React.createElement(ArrowDropUp, {
                        color: "inherit",
                        viewBox: "4 4 16 16"
                      }) : React.createElement(ArrowDropDown, {
                        color: "inherit",
                        viewBox: "4 4 16 16"
                      })), React.createElement("div", {
                  className: "text-lg"
                }, "(" + (
                  delta > 0 ? "+" : ""
                ) + Global.toFixed(undefined, delta) + ")"));
}

var TopTrend = {
  size: 32,
  make: TopTrends$TopTrend
};

function TopTrends(Props) {
  var currStats = Props.currStats;
  var pastStats = Props.pastStats;
  var aperture = ApertureCtx.useValue(undefined);
  var t = Translation.useT(/* Common */0);
  var topTrends = React.useMemo((function () {
          var units = Belt_Array.keepMap(Js_dict.entries(currStats.units), (function (param) {
                  var stats = param[1];
                  var id = param[0];
                  return Belt_Option.map(Js_dict.get(pastStats.units, id), (function (ps) {
                                return [
                                        id,
                                        stats,
                                        ps
                                      ];
                              }));
                }));
          var traits = Belt_Array.keep(Belt_Array.keepMap(Js_dict.entries(currStats.traits), (function (param) {
                      var stats = param[1];
                      var id = param[0];
                      return Belt_Option.map(Js_dict.get(pastStats.traits, id), (function (ps) {
                                    return [
                                            id,
                                            stats,
                                            ps
                                          ];
                                  }));
                    })), (function (param) {
                  if (Global.f(param[1].count) / Global.f(currStats.totalEntries) > 0.00125) {
                    return true;
                  } else {
                    return Global.f(param[2].count) / Global.f(pastStats.totalEntries) > 0.00125;
                  }
                }));
          return getTopTrends(units, traits);
        }), [
        currStats,
        pastStats
      ]);
  return React.createElement(Link.make, {
              className: "mt-8 w-full max-w-[100vw]",
              route: {
                TAG: /* Stats */0,
                _0: {
                  TAG: /* Trends */13,
                  aperture: {
                    patch: Aperture.$$default.patch,
                    rankGroup: aperture.rankGroup,
                    gameType: Aperture.$$default.gameType,
                    queue: Aperture.$$default.queue
                  }
                }
              },
              children: React.createElement(ContentSection.make, {
                    className: "flex cursor-pointer",
                    children: null,
                    noPadding: true
                  }, React.createElement("div", {
                        className: "w-[130px] pl-4 text-lg h-12 leading-[48px] font-medium flex-shrink-0 z-10 bg-bg2 shadow-[6px_0_5px_-2px_rgba(0,0,0,0.6)]"
                      }, t("Top Trends")), React.createElement("div", {
                        className: "w-full overflow-x-hidden py-2 top-trends-wrap relative"
                      }, React.createElement("div", {
                            className: "flex top-trends"
                          }, Belt_Array.mapWithIndex(topTrends, (function (idx, param) {
                                  return React.createElement(TopTrends$TopTrend, {
                                              entity: param[0],
                                              delta: param[1],
                                              place: param[2],
                                              key: String(idx)
                                            });
                                })), Belt_Array.mapWithIndex(Belt_Array.slice(topTrends, 0, 10), (function (idx, param) {
                                  return React.createElement(TopTrends$TopTrend, {
                                              entity: param[0],
                                              delta: param[1],
                                              place: param[2],
                                              key: String(idx)
                                            });
                                }))), React.createElement("div", {
                            className: "top-trends-overlay hidden items-center justify-end absolute right-0 top-0 bottom-0 w-[128px] px-2 font-montserrat font-semibold text-primary-11"
                          }, "View All")))
            });
}

var make = TopTrends;

export {
  getTopTrends ,
  TopTrend ,
  make ,
  
}
/* Link Not a pure module */
